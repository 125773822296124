<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    iconId: string
    width: string
    height: string
    stroke?: string
    fill?: string
}>()

const styles = computed(() => {
    return {
        width: `${ props.width }px`,
        height: `${ props.height }px`
    }
})
</script>

<template>
    <span :style="styles" class="the-icon-component">
        <svg
            :class="$attrs.class"
            :width="width"
            :height="height"
            :stroke="stroke"
            :fill="fill"
        >
            <use :href="`/images/icons.svg#${ iconId }`" />
        </svg>
    </span>
</template>